<template>
    <div>
        <v-dialog v-model="$store.state.dialog2" width="400">
            <v-card flat class="card">
                <v-toolbar outlined flat>
                    <v-toolbar-title>{{$store.state.itemDetails.transactionType == 0 ? 'تسديد' : 'استلام'}}</v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
    
                <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                    <!-- <pre dir="ltr">
                    {{ item }}
                    </pre> -->
                    <v-container fluid>
                        <v-card outlined>
                            <v-container fluid>
                                
                                <v-row>
                                    <h3 class="mr-4 mt-3">المبلغ: {{ $service.formatCurrency($store.state.itemDetails.amount) }} </h3> 
                                    <h3 class="mr-4 mt-3">المبلغ المسدد: {{ $service.formatCurrency($store.state.itemDetails.paidAmount) }}</h3>
                                    <h3 class="mr-4 mt-3">المبلغ المتبقي: {{ $service.formatCurrency($store.state.itemDetails.amount - $store.state.itemDetails.paidAmount) }}</h3>
                                    <aMoneyField :key="$store.state.itemDetails.amount" v-model="item.amount" title="المبلغ" :cols="12" :md="12" :required="true" />
                                </v-row>
    
                            </v-container>
                        </v-card>
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :disabled="!valid" :loading="loading" x-large color="primary" class="btn">حفظ</v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
    </template>
    
    <script>
    export default {
        
    
        data() {
            return {
                item: {},
                valid: false,
                loading: false,
                warehouses: []
            }
        },
    
        created() {
    
            this.reset()
            this.$eventBus.$on('fill-fields', () => {
            const currentAmount = this.$store.state.itemDetails.amount - this.$store.state.itemDetails.paidAmount
            this.item = { ...this.item, amount: currentAmount };

            // if(this.$store.state.itemDetails.transactionType == 1){
            //     this.item.amount = this.$store.state.itemDetails.order.packageCost
            // }else if(this.$store.state.itemDetails.transactionType == 0){
            //     if(this.$store.state.itemDetails.user.userType.id == 3) // Merchant
            //     {
            //         this.item.amount = this.$store.state.itemDetails.order.packageValue
            //     }else if(this.$store.state.itemDetails.user.userType.id == 5) // delivery
            //     {
            //         this.item.amount = this.$store.state.itemDetails.order.deliveryCost
            //     }
            // }
            // this.item.amount = this.$store.state.itemDetails.transactionType == 0 ? 'تسديد' : 'استلام'
            })
    
        },
        
        methods: {
    
            async submit() {
                this.loading = true
                try {
                    await this.$http.put(`PaymentTransaction/${this.$store.state.itemDetails.transactionType == 0 ? 'payOrder' : 'ReceivingAmountFromDelivery'}/${this.$store.state.itemDetails.id}`, this.item)
                    this.$service.success('تم الإضافة بنجاح')
                    this.$eventBus.$emit(`${this.$route.path}`)
                    this.close()
                }catch(err){
                    this.$service.faild('حدث خطأ')
                    this.$service.faild(err.data.message)
                    this.$service.faild(JSON.stringify(err.data.result.message))
                }finally{
                    this.loading = false
                }
            },
    
            reset() {
                this.item = {
                    "amount": ""
                }
            },

            close() {
                this.$store.commit('setDialog2')
                this.reset()
                this.resetValidation()
                this.$store.commit('setItemDetails', {})
            },
    
            resetValidation() {
                this.$refs.form.resetValidation()
            },
    
        },
    }
    </script>
    